<template>
    <div id="send-bug-page">
        <toolbar-back-button-component :title="$t('send_bug_page.signal_bug')" />

        <div class="pa-4">
            <v-text-field v-model="data.page_concerned" :label="$t('send_bug_page.page_concerned')" class="mt-2 white-text-field" filled />

            <v-text-field v-model="data.object" :label="$t('send_bug_page.bug_object')" class="white-text-field" filled />

            <v-textarea v-model="data.details" :label="$t('send_bug_page.bug_details')" class="white-text-field" />

            <div class="dropzone" @dragenter="dragging = true" @dragleave="dragging = false">
                <div class="dropzone-info" @drag="onChange">
                    <div class="dropzone-upload-limit-info pl-3 pt-4 pb-4">
                        <span :class="dragging ? 'primary--text' : ' font-color-medium'">
                            {{ $t('send_bug_page.import_image') }}
                        </span>
                        <p class="font-color-light font-14 mb-n1 line-height-16">
                            {{ $t('send_bug_page.drop_or_click_image') }}
                        </p>
                        <p class="font-color-light font-10 mb-0">
                            {{ $t('global.format_pgn_jpeg') }}
                        </p>
                        <input multiple type="file" @change="onChange" />
                    </div>
                </div>

                <div v-for="(file, index) in files" :key="index" class="mb-1 ml-2 font-14">
                    <a :href="urls[index]" target="_blank">{{ file.name }}</a>
                    <v-icon small @click="files.splice(index, 1)">mdi-close</v-icon>
                    <!--
                    <v-row class="ma-0">
                        <v-col v-for="url in urls" :key="url" >
                            <v-img :src="url" max-width="200" max-height="200"/>
                        </v-col>
                    </v-row>
                    -->
                </div>
            </div>

            <div class="font-12 mx-1 mt-1 mb-6">
                <span class="font-color-light">Exemple :</span><br />
                <span class="font-weight-bold font-color-medium">{{ $t('send_bug_page.behavior_observe') }}</span>
                <br />
                <span class="font-color-light font-italic"> {{ $t('send_bug_page.example_bug') }} </span><br />
                <span class="font-weight-bold font-color-medium">{{ $t('send_bug_page.behavior_achieve') }}</span>
                <br />
                <span class="font-color-light font-italic">
                    {{ $t('send_bug_page.example_solution') }}
                </span>
            </div>

            <v-btn :disabled="!valid" :loading="loading_edit" block class="btn-secondary-loader" color="primary" large @click="sendBug()">
                <span :class="valid ? 'font-weight-bold secondary--text' : 'font-weight-bold font-color-light'">
                    {{ $t('send_bug_page.send_report_bug') }}
                </span>
            </v-btn>
        </div>
    </div>
</template>

<script>
import GlobalRepository from '../../../repositories/GlobalRepository'
import ToolbarBackButtonComponent from '../../../components/Global/ToolbarBackButtonComponent'

export default {
    name: 'SendBugPage',
    components: { ToolbarBackButtonComponent },
    computed: {
        valid() {
            return this.data.page_concerned !== '' && this.data.object !== '' && this.data.details !== '' && this.files.length > 0
        },
    },
    data() {
        return {
            data: {
                page_concerned: '',
                object: '',
                details: '',
            },
            loading_edit: false,

            files: [],
            dragging: false,
            urls: [],
            valid_format_picture: 'image/png, image/jpeg',
        }
    },
    methods: {
        sendBug() {
            this.loading_edit = true

            let formData = new FormData()

            this.files.forEach((file) => {
                formData.append('files[]', file)
            })

            formData.append('page_concerned', this.data.page_concerned)
            formData.append('object', this.data.object)
            formData.append('details', this.data.details)

            GlobalRepository.sendBug(formData)
                .then(() => {
                    this.showSnackbar('success', this.$t('send_bug_page.message_send'))
                    this.data.opinion = ''
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_edit = false
                    this.navigateTo('parameter_comment')
                })
        },

        onChange(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                this.dragging = false
                return
            }

            if (this.checkFiles(files[0])) {
                this.createFile(files[0])
            }
        },

        checkFiles(new_file) {
            let valid = true

            if (new_file.type === "") {
                this.showSnackbar('error', this.$t('send_bug_page.file_type_not_authorized'))
                valid = false
            }

            if (!this.valid_format_picture.includes(new_file.type)) {
                this.showSnackbar('error', this.$t('send_bug_page.file_type_not_authorized'))
                valid = false
            }

            this.files.forEach((file) => {
                if (file.name === new_file.name) {
                    this.showSnackbar('error', this.$t('send_bug_page.file_already_selected'))
                    valid = false
                }
            })

            return valid
        },

        createFile(file) {
            this.files.push(file)
            this.urls.push(URL.createObjectURL(file))
            this.dragging = false
        },
    },
}
</script>

<style>
.dropzone {
    width: 100%;
    position: relative;
    border: 1px dashed #7f828a;
    background: var(--v-white-base);
    border-radius: 5px 5px 0 0 !important;
}

.dropzone:hover {
    border: 1px dashed var(--v-primary-base);
}

.dropzone-upload-limit-info {
    position: relative;
    height: fit-content;
}

.dropzone input {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}
</style>
